// import React from "react";

// import { Tween } from "react-gsap";
// // import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
// // gsap.registerPlugin(ScrollTrigger);

// export default function FullPageScroll() {
//   return (
//     <div style={{ height: "300vh" }}>
//       <div style={{ height: 300 }}></div>
//       <Tween
//         to={{
//           y: "0px",
//           scrollTrigger: {
//             trigger: "#square",
//             start: "100px center",
//             end: "200px center",
//             scrub: 0.5,
//             markers: true,
//           },
//         }}
//       >
//         <div
//           id="square"
//           style={{ width: "100px", height: "100px", background: "#ccc" }}
//         />
//       </Tween>
//     </div>
//   );
// }

import React from "react";

export default function Test() {
  return <div>Test</div>;
}
