import React, { useState } from "react";
import useIntersectionObserver from "../hooks/useIntersectionObserver";

const LazyImage = ({
  src,
  alt,
  delay = 200,
  placeholderColor = "transparent",
  ...props
}) => {
  const [loadedSrc, setLoadedSrc] = useState(src);
  const [hasError, setHasError] = useState(false);

  const handleIntersection = () => {
    setLoadedSrc(src);
  };

  const handleError = () => {
    setHasError(true);
  };

  const setElement = useIntersectionObserver(handleIntersection, null, delay);

  return (
    <div
      ref={setElement}
      style={{
        backgroundColor: placeholderColor,
        display: "inline-block",
      }}
      {...props}
    >
      {loadedSrc && !hasError ? (
        <img
          src={loadedSrc}
          alt={alt}
          onError={handleError}
          style={{
            opacity: loadedSrc ? 1 : 0.5,
            transition: "opacity 0.5s ease-in",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            backgroundColor: placeholderColor,
          }}
        />
      ) : null}
    </div>
  );
};

export default LazyImage;
