import React from "react";
import "./style.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  dataabout,
  worktimeline,
  oldworktimeline,
  skills,
  pageIds,
} from "../../content_option";
import LazyImage from "../../components/LazyImage";

export const About = () => {
  return (
    <Container id={pageIds.about + "-container"} className="About-header">
      <Row className="mb-5 mt-3 pt-md-3">
        <Col lg="8">
          <h1 className="display-4 mb-4"> About </h1>{" "}
          <hr className="t_border my-4 ml-0 text-left" />
        </Col>
      </Row>
      <Row className="sec_sp mt-lg-5">
        <Col lg="5">
          <h3 className="color_sec py-4">{dataabout.title}</h3>
        </Col>
        <Col lg="7" className="d-flex align-items-center">
          <div>
            <p>{dataabout.aboutme}</p>
          </div>
        </Col>
      </Row>
      <Row className=" sec_sp">
        <Col lg="5">
          <h3 className="color_sec py-4">Work Timeline</h3>
        </Col>
        <Col lg="7">
          <table className="table caption-top">
            <tbody>
              {(isDateGreaterThanJune26() ? worktimeline : oldworktimeline).map(
                (data, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">
                        <div
                          className="d-flex align-items-center company"
                          onClick={() =>
                            window.open(
                              data.url,
                              "_blank",
                              "noopener,noreferrer"
                            )
                          }
                        >
                          <div className="me-2 company-name">{data.where}</div>
                          <img
                            className="d-flex company-img"
                            src={data.img}
                            alt=""
                          />
                        </div>
                        <div className="post">{data.jobtitle}</div>
                      </th>
                      <td className="company-date">{data.date}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className="sec_sp">
        <Col lg="5">
          <h3 className="color_sec py-4">Skills</h3>
        </Col>
        <div className="about_skills">
          {skills.map((data, i) => {
            return (
              <div key={i} className="skill_container">
                {/* <img className="d-flex skill_logo_main" src={data.logo} alt="" /> */}
                <LazyImage
                  placeholderColor="gray"
                  className="d-flex skill_logo_main"
                  alt="skill"
                  src={`https://skillicons.dev/icons?i=${data.logo}`}
                  delay={0}
                />
                <h3 className="progress-title">{data.name}</h3>
              </div>
            );
          })}
          {/* <img alt='skills' src="https://skillicons.dev/icons?i=ts"  />
            <img alt='skills' src="https://skillicons.dev/icons?i=javascript,ts,react,redux,html,css,nextjs,styledcomponents,tailwind,angular,nodejs,figma,firebase,express,git,github,postman,vscode"  />
            <img alt='skills' src="https://skillicons.dev/icons?i=javascript,ts,react,redux,html,css,nextjs,styledcomponents,tailwind,angular,nodejs,figma,firebase,express,git,github,postman,vscode"  /> */}
        </div>
      </Row>

      {/* <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Sample works</h3>
          </Col>
          <Col lg="7 about_skills">
            {dataportfolio.map((data, i) => {
              return (
                <div key={i} className="po_item">
                <img src={data.img} alt="" />
                <div className="content">
                  <p>{data.description}</p>
                  <a href={data.link}>view project</a>
                </div>
              </div>
              );
            })}
          </Col>
        </Row> */}
    </Container>
  );
};

function isDateGreaterThanJune26() {
  const currentYear = new Date().getFullYear();
  const june26th = new Date(currentYear, 6, 2); // Months are zero-indexed, so 5 is June

  return new Date() > june26th;
}
