import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import withRouter from "../hooks/withRouter";
import AppRoutes from "./routes";
import Headermain from "../header";
import "./App.css";
import RNAnimations from "../privacy-policies/RNAnimations";
import TicTacToe from "../privacy-policies/TicTacToe";
import Products from "../pages/products";
import ChatTest from "../test/ChatTest";
import Test from "../test/Test.js";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    if (!window.location.hash.includes("#"))
      setTimeout(() => window.scrollTo({ top: 0, behavior: "instant" }), 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

function App() {
  return (
    <div className="portfolio-container">
      <ScrollToTop>
        <Headermain />
        <AppRoutes />
      </ScrollToTop>
    </div>
  );
}

export default function MainApp() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/rnanimations/privacy-policy" element={<RNAnimations />} />
        <Route path="/tictactoe/privacy-policy" element={<TicTacToe />} />
        <Route path="/prods" element={<Products />} />
        <Route
          path="/linkedin"
          element={
            <RedirectToUrl url="https://www.linkedin.com/in/aswincheriyala" />
          }
        />
        <Route
          path="/leetcode"
          element={<RedirectToUrl url="https://leetcode.com/aswincheriyala" />}
        />
        <Route path="/chattest" element={<ChatTest />} />
        <Route path="/test" element={<Test />} />
        <Route path="*" element={<App />} />
      </Routes>
    </Router>
  );
}

function RedirectToUrl({ url }) {
  window.location.href = url;
  return null;
}
